import { template as template_2797619081f24e29b58e1be26389b7a7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_2797619081f24e29b58e1be26389b7a7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
