import { template as template_7d291bc1f46645c4bfb84e4895943671 } from "@ember/template-compiler";
const FKLabel = template_7d291bc1f46645c4bfb84e4895943671(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
