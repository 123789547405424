import { template as template_cf3d7888e40b49b085aab3de66b8da51 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cf3d7888e40b49b085aab3de66b8da51(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
