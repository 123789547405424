import { template as template_a02ed4d5ce2b44f7b27043a66148a1e3 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_a02ed4d5ce2b44f7b27043a66148a1e3(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
